import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { LangService } from "@app/services/lang.service"
import { ModalService } from "@app/services/modal.service"
import { environment } from "@env/environment"

type AvailableGetPaths = "prep_login_screen" | "get-csrf"
type AvailableUploadPaths =
	| "upload_user_logo"
	| "create_fp_image"
	| "upload_document"
	| "parse_xlsx"
	| "save_gen_item"
	| "save_gen_field"

export type AvailablePostPaths =
	| "post_invoice_refresh"
	| "send_all_reports"
	| "create_task"
	| "create_meeting_request"
	| "update_consent_to_sms"
	| "update_role_perms"
	| "update_user_perms"
	| "duplicate_role"
	| "delete_assoc"
	| "save_followup"
	| "user_login"
	| "ajax_account_confirmation_1"
	| "ajax_account_confirmation_2"
	| "ajax_otp_validation"
	| "ajax_otp_validation_2"
	| "sub_user_otp"
	| "potential_user_otp"
	| "user_regi"
	| "sub_user_regi"
	| "potential_user_regi"
	| "reset_password"
	| "change_password"
	| "forgot_password"
	| "update_wants_instruction"
	| "update_saw_notification"
	| "get_user_google2fa_secret"
	| "create_potential_user"
	| "send_invite"
	| "duplicate_test"
	| "is_financially_new"
	| "get_meeting_reqeusts"
	| "deactivate_meeting_request"
	| "update_test_question"
	| "renew_prescription"
	| "import_followups"
	| "import_tags"
	| "import_branches"
	| "import_patients"
	| "get_patient_fields_tbl"
	| "get_profitloss_list"
	| "get_taxes_list"
	| "get_invoice_list"
	| "get_invoice_list_by_payment_method"
	| "get_admininvoice_list"
	| "get_followup_list"
	| "get_followup_all_list"
	| "get_followups_sub_user_report"
	| "get_invoices_sub_user_report"
	| "get_followups_sub_user_rent_report"
	| "get_proforma_list"
	| "get_justinvoice_list"
	| "get_expense_list"
	| "get_payorprices_list"
	| "get_fus_rent_list"
	| "get_fus_locations_list"
	| "save_medicpatient"
	| "update_tax"
	| "update_solo_time_allocation"
	| "update_time_allocation"
	| "get_real_debt"
	| "sign_documents_list"
	| "get_graphs_data"
	| "sub_user_payments_create_expense"
	| "sub_user_payments_update_sum"
	| "get_sub_user_payments"
	| "get_patients_sub_users"
	| "get_followups_update_list"
	| "get_followups_for_supervisor_list"
	| "email_multiple_finresources"
	| "save_finresource"
	| "get_finresource"
	| "get_site_data"
	| "search_branches"
	| "search_medics"
	| "search_diagps"
	| "get_api_calls_permissions"
	| "assign_multi_user"
	| "get_next_projected_id"
	| "get_last_fus_update"
	| "delete_followupserie"
	| "user_holiday_update"
	| "has_followups_with_text"
	| "get_followup_by_id"
	| "patient_logout"
	| "logout"
	| "send_users_email"
	| "get_all_fus"
	| "guest_get_all_fus"
	| "guest_get_cal_data"
	| "save_meeting_request"
	| "report_bug"
	| "get_api_data"
	| "pre_send_proforma_text"
	| "get_followup_for_period"
	| "change_series_limit_date"
	| "get_future_fuses"
	| "update_default_signature"
	| "get_last_followup_price"
	| "update_configcliniq_data"
	| "update_sub_user_configcliniq_data"
	| "get_sub_user_configcliniq_data"
	| "pay_by_token"
	| "get_client_takbull_redirect_link"
	| "get_client_grow_redirect_link"
	| "get_takbull_redirect_link"
	| "get_client_payment_form_data"
	| "get_payment_form_data"
	| "get_user_type_to_num"
	| "get_activity"
	| "get_user_data"
	| "followup_has_non_attend"
	| "get_followup_for_series"
	| "get_prev_next_fus"
	| "send_err"
	| "get_contact_data"
	| "contact_has_email"
	| "get_payor_data"
	| "get_fp_images"
	| "delete_fp_image"
	| "open_close_task"
	| "is_patient_locked"
	| "release_patient_lock"
	| "get_patient_data"
	| "send_proforma_text"
	| "add_task_recipient"
	| "delete_task_assoc"
	| "add_task_assoc"
	| "get_patient_test_fills_answers"
	| "set_is_uptodate"
	| "update_inquiry_status_id"
	| "create_task_comment_and_status"
	| "get_task_notifications_total"
	| "save_headture_areas"
	| "get_headture_demo"
	| "email_report_pdf"
	| "add_new_scheduled_reports"
	| "update_patient"
	| "mr_partner_response"
	| "send_mr_to_partner"
	| "get_mr_motions_rows"
	| "invite_partner"
	| "get_patients_for_site_data"
	| "create_task_motion_by_patient_id"
	| "delete_task_recipient"
	| "get_bkmvdata"
	| "get_contactway_patients"
	| "patient_test_fill_init"
	| "patient_test_invite"
	| "flip_location"
	| "unify_patients"
	| "get_tasks"
	| "prepare_for_save"
	| "delete_gen_item"
	| "save_gen_item"
	| "save_gen_field"
	| "get_gen_items"
	| "email_tests"
	| "confirm_accept_partner"
	| "mr_partner_accept"
	| "get_followups_and_credit_for_fin_resource"
	| "get_last_finresouce_item_by_person"
	| "get_patient_details_by_dates"
	| "get_payor_details_by_dates"
	| "get_session_conf_data"
	| "get_debt_items"
	| "flip_order_num"
	| "logout_and_login_as_mirror"
	| "patient_details_fill_init"
	| "patient_fill_details"
	| "invite_patient_fill_details"
	| "approve_patient_fill_details"
	| "get_israel_invoice_number"
	| "get_guest_payment_form_data"
	| "get_guest_redirect_link"
	| "print_make_finresource"
	| "delete_assoc_multi"
	| "get_test_graph_scores"
	| "get_testscales"
	| "create_testschedule"

type AvailableDownloadPaths =
	| AvailablePostPaths
	| "print_mixed_list"
	| "patient_answer_test"
	| "download_testfill"
	| "answer_test"
	| "get_bkmvdata"
	| "export_to"
	| "export_to_xlsx"
	| "export_all_patients"
	| "download_zip"
	| "download_product_persons"

const contentType = { "Content-Type": "application/json" } //normal content type for api calls
const contentTypeFileUpload = { enctype: "multipart/form-data" } //content type specifically for uploading files
const httpOptions = {
	//regular http options
	withCredentials: true,
	headers: new HttpHeaders(contentType),
}
const httpOptionsFileUpload = {
	//upload specific http options
	withCredentials: true,
	headers: new HttpHeaders(contentTypeFileUpload),
}
const httpOptionsFileDownload: any = { ...httpOptions, responseType: "blob" } //download file specific http options
// const urlBase = "http://manage-5.my/api/v2";
const urlBase = environment.apiUrl //the api url to send to (changes by local/dev/production)

@Injectable({
	providedIn: "root",
})
export class ApiService {
	postLogin: string = "" //if arrived from a different url and redirected to login -  holds the url to navigate to after login
	csrf = "" //holds the crsf code (from the cookie)
	curCsrfErrors: number = 0 //counter for csrf errors (currently all api errors). will redirect to network error if too many
	isSendingApiPerms: boolean = false //flag for "currently sending api permissions". to prevent and queue sending gen_items requests before
	permWaitingFuncs: any[] = [] //a queue for all gen_items requests waiting for permissions to return
	isUserLoggedIn: boolean = false //logged in (for app html and timeout function)
	isPatientLoggedIn: boolean = false //logged in (for app html and timeout function)
	timeout: any = null //holds the timeout function to logout
	logoutInMins: number = 15 //default mins to logout (will be overriden by user data)
	lastActivity: Date = null //will hold the date object of the most recent activity (mouse move, click..)

	isStarted: boolean = false

	apiCallPerms: any = null

	constructor(
		private http: HttpClient,
		private router: Router,
		public modalService: ModalService,
		public lang: LangService
	) {}

	async verifyCsrf() {
		//on init or api error - request new csrf (from cookie or api if no cookie). CURRENTLY always asks from api
		await this.get("get-csrf") //request from api then try to attach from cookie
		this.isStarted = true
	}

	createFormUpload(obj: any, filesObj: any = {}) {
		//use an object with keys and values and a files object and merge them into a FormData object (to be able to upload via api)
		const formData: FormData = new FormData()
		// formData.append(fileFieldName, file, file.name);
		Object.keys(obj).forEach((key) => {
			//iterate the keys and add the key-value to the FormData
			formData.append(key, obj[key])
		})
		Object.keys(filesObj).forEach((key) => {
			//add all the files to the FormData
			formData.append(key, filesObj[key])
		})
		return formData
	}

	promise(apiFunc: any): Promise<any> {
		return new Promise((resolve) => {
			apiFunc.subscribe((res: any) => {
				resolve(res)
			})
		})
	}

	postPromise(path: string, obj: any = {}, opts: any = null): Promise<any> {
		return this.promise(
			this.http.post(`${urlBase}/${path}`, obj, opts || httpOptions)
		)
	}
	getPromise(path: string): Promise<any> {
		return this.promise(this.http.get(`${urlBase}/${path}`, httpOptions))
	}

	get(path: AvailableGetPaths): Promise<any> {
		return this.getPromise(path)
	}
	postWithVars(
		path: AvailablePostPaths,
		obj: any = {},
		fieldsLimiter: string = null
	): Promise<any> {
		if (fieldsLimiter) {
			obj = pluck(obj, fieldsLimiter)
		}
		const params = Object.keys(obj)
			.map(
				(key) => encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
			)
			.join("&")
		return this.promise(
			this.http.post(`${urlBase}/${path}?${params}`, {}, httpOptions)
		)
	}

	post(
		path: AvailablePostPaths,
		obj: any = {},
		fieldsLimiter: string = null
	): Promise<any> {
		if (fieldsLimiter) {
			obj = pluck(obj, fieldsLimiter)
		}
		return this.postPromise(path, obj)
	}
	download(
		path: AvailableDownloadPaths,
		obj: any = {},
		fieldsLimiter: string = null
	): Promise<any> {
		if (fieldsLimiter) {
			obj = pluck(obj, fieldsLimiter)
		}
		return this.postPromise(path, obj, httpOptionsFileDownload)
	}
	upload(path: AvailableUploadPaths, obj: any): Promise<any> {
		const formData: FormData = new FormData()
		Object.keys(obj).forEach((key) => {
			//iterate the keys and add the key-value to the FormData
			formData.append(key, obj[key])
		})
		return this.postPromise(path, formData, httpOptionsFileUpload)
	}

	postSubscribe(path: string, obj: any, func: any) {
		return this.http
			.post(`${urlBase}/${path}`, obj, httpOptions)
			.subscribe(func)
	}

	decryptInCollection(objectCollection: any[]) {
		//iterate a collection and decrypt all items in it
		objectCollection.forEach((it) => {
			this.decryptItem(it)
		})
	}
	decryptItem(it: any) {
		//iterate all fields of an object, override when _dec exists
		Object.keys(it).forEach((field) => {
			if (field.endsWith("_dec")) {
				//if field ends with "_dec"
				let fieldNoDec = field.substring(0, field.length - 4) //find the base name
				it[fieldNoDec] = it[field] //override the base with the "_dec" value
			}
		})
		it.errors = {} //make sure to add an errors field
	}

	downloadFile(resultStream: any, name: string) {
		//download file from stream (creates a link to it and dl)
		const blob = new Blob([resultStream], { type: "octet/stream" }) //create blob from the stream returned from server
		const url = window.URL.createObjectURL(blob) //create url data
		let el = document.createElement("A") as any //create link element
		el.style.display = "none" //link has no display
		el.href = url //add the url data
		el.download = name //give the download its name
		document.body.appendChild(el) //add link element to document
		el.click() //click the link to force download
		window.URL.revokeObjectURL(url) //free the resource
		document.body.removeChild(el) //remove the link
	}

	previewDownload(resultStream: any) {
		const blob = new Blob([resultStream], { type: "application/pdf" }) //create blob from the stream returned from server
		const url = window.URL.createObjectURL(blob) //create url data
		window.open(url)
		window.URL.revokeObjectURL(url) //free the resource
	}
	async sendApiGetPerms(cbFunc: any = null) {
		//if permissions were not called - queue the request and request permissions
		if (this.apiCallPerms) {
			return cbFunc ? cbFunc() : null
		} //permissions already loaded - just run the callback function
		if (cbFunc) {
			//if callback function exists - add it to queue
			this.permWaitingFuncs.push(cbFunc)
		}

		if (this.isSendingApiPerms) {
			return
		} //if flagged as already "currently sending api permissions" - return

		this.isSendingApiPerms = true //flag as "currently sending api permissions"

		const res: any = await this.post("get_api_calls_permissions")
		this.isSendingApiPerms = false //flag as false "currently sending api permissions"
		this.apiCallPerms = res //save to table service field
		this.permWaitingFuncs.forEach((cb) => cb()) //run all callback functions
	}
	logoutCommon() {
		this.isStarted = false
		clearTimeout(this.timeout) //clear the timeout
		this.isUserLoggedIn = false
		this.isPatientLoggedIn = false
		sessionStorage.removeItem("cliniqData") //remove storage items
		sessionStorage.removeItem("siteData")
		sessionStorage.removeItem("isPatientLoggedIn")
	}

	async doLogout() {
		//run logout process (clear timeout, remove storage items, request logout from server, redirect to login page)
		const isPatient = this.isPatientLoggedIn
		this.logoutCommon()
		if (isPatient) {
			// await this.post("patient_logout");
			// window.location.assign("/patient-login");	//redirect to login page
		} else {
			await this.post("logout")
			window.location.assign("/login") //redirect to login page
		}
		// this.modalService.closeAllModals();
		// window.scroll(0,0);
	}

	resetLastActivity() {
		this.lastActivity = new Date() //reset last activity date
		localStorage.setItem("lastActivity", this.lastActivity.toISOString())
	}

	async restartLogoutTimeout(overrideMins: number = null) {
		//reset the timeout, recreate logout function

		if (!this.isUserLoggedIn && !this.isPatientLoggedIn) {
			return
		} //not logged in - exit
		if (overrideMins) {
			//if passed minutes to override, override the local field
			this.logoutInMins = overrideMins
		}
		if (!this.lastActivity) {
			let localStorageLastActivity: any = localStorage.getItem("lastActivity")

			if (!localStorageLastActivity) {
				return this.doLogout()
			}
			localStorageLastActivity = localStorageLastActivity.trim()
			try {
				localStorageLastActivity = new Date(localStorageLastActivity)
				if (
					new Date().getTime() - localStorageLastActivity.getTime() >
					this.logoutInMins * 60 * 1000
				) {
					//if no last activity date or is smaller than the logout minutes - logout
					return this.doLogout()
				}
			} catch (err) {
				return this.doLogout()
			}
		}
		if (
			this.lastActivity != null &&
			new Date().getTime() - this.lastActivity.getTime() >
				this.logoutInMins * 60 * 1000
		) {
			//if  last activity date and is smaller than the logout minutes - logout
			return this.doLogout()
		}

		this.resetLastActivity()
		clearTimeout(this.timeout) //clear the timeout
		let timeoutMillis = this.logoutInMins * 60 * 1000 //the timeout in millis

		this.timeout = setTimeout(() => {
			//set a new logout timeout and save to field
			console.log("out....")
			this.doLogout()
		}, timeoutMillis)
	}
	postLoginPatient() {
		this.isPatientLoggedIn = true
		sessionStorage.setItem("isPatientLoggedIn", "1")
		this.resetLastActivity()
	}
	get_session_conf_data() {
		//has to stay as an observable because of pipe
		return this.http.post(`${urlBase}/get_session_conf_data`, {}, httpOptions)
	}

	delete_gen_item(tableName: string, id: number) {
		return this.post("delete_gen_item", { tableName, id })
	}
	async save_gen_item_files(obj: any) {
		//tries to decrypt the returned item
		const res: any = await this.upload("save_gen_item", obj)
		this.decryptItem(res)
		return { ...res }
	}
	async save_gen_item(tableName: string, item: any) {
		//tries to decrypt the returned item
		const res: any = await this.post("save_gen_item", {
			tableName,
			mode: "add",
			...item,
		})
		this.decryptItem(res)
		return { ...res }
	}

	save_gen_field_file(
		tableName: string,
		id: number,
		fieldName: string,
		newValue: any
	) {
		return this.upload("save_gen_field", { tableName, id, fieldName, newValue })
	}
	save_gen_field(
		tableName: string,
		id: number,
		fieldName: string,
		newValue: any
	) {
		return this.post("save_gen_field", { tableName, id, fieldName, newValue })
	}

	async get_gen_items(tableName: string, sendObj: any = null) {
		//enables paging, tries to decrypt the returned collection
		const res: any = await this.post("get_gen_items", { tableName, ...sendObj })
		let col = res[tableName]
		if (col.data) {
			//pagi
			this.decryptInCollection(col.data)
			return { ...res, [tableName]: { ...col } }
		} else {
			this.decryptInCollection(col)
			return { ...res, col }
		}
	}
}

export function pluck(obj: any, fields: string) {
	return fields.split(",").reduce((acc: any, it: any) => {
		acc[it] = obj[it]
		return acc
	}, {})
}
